import React, { Component } from 'react'
import { IoCartOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./Cart.css"
import { Link } from 'react-router-dom';

export class Cart extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  getTranslation(language) {
    const translations = {
      en: {
        cart: 'Cart',
        total: 'Total',
        pay: 'Pay',
        product: 'Product',
        quantity: 'Quantity',
        price: 'Price',
        totalAmount: 'Total',
        emptyCart: 'The cart is empty',
        placeOrder: 'Place Order'
      },
      rus: {
        cart: 'Корзина',
        total: 'Итого',
        pay: 'Оплатить',
        product: 'Товар',
        quantity: 'Количество',
        price: 'Цена',
        totalAmount: 'Итого',
        emptyCart: 'Корзина пуста',
        placeOrder: 'Оформить заказ'
      },
      հայ: {
        cart: 'Զամբյուղ',
        total: 'Ընդհանուր',
        pay: 'Վճարել',
        product: 'Ապրանք',
        quantity: 'Քանակ',
        price: 'Գին',
        totalAmount: 'Ընդհանուր',
        emptyCart: 'Զամբյուղը դատարկ է',
        placeOrder: 'Գրանցել պատվեր'
      }
    }
    return translations[language];
  }

  render() {
    const language = this.props.language;
    const translation = this.getTranslation(language);

    const totalItems = this.props.totalItems;
    const cart_items = this.props.cart;
    return (
      <div className="cart" ref={node => { this.node = node; }}>
        <div onClick={this.props.handleOpenCart}>
          <IoCartOutline />
        </div>
        <span className="cart__counter" onClick={this.props.handleOpenCart}>{totalItems}</span>
        {this.props.cartIsOpen &&
          <div className="cart__dropdown">
            <span className="close__dorpdown" onClick={this.props.handleOpenCart}>X</span>
            <div className="items">
              {cart_items.length > 0 ? (
                <div className="item__table">
                  <table>
                    <thead>
                      <tr>
                        <th>{translation.product}</th>
                        <th>{translation.quantity}</th>
                        <th>{translation.price}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart_items.map((item, index) => (
                        <tr key={index}>
                          <td className='header__cart__product__description'>{item.description}</td>
                          <td className='item__table__quantity__number'>
                            <button onClick={(e) => { e.stopPropagation(); this.props.decrementQuantity(item); }}>-</button>
                            {item.quantity}
                            <button onClick={(e) => { e.stopPropagation(); this.props.incrementQuantity(item); }}>+</button>
                          </td>
                          <td>{parseInt(item.price)} դրամ</td>
                          <td onClick={() => this.props.removeFromCart(item)}><RiDeleteBin6Line /></td>
                        </tr>
                      ))}
                      <tr className="total">
                        <td>{translation.totalAmount}</td>
                        <td></td>
                        <td>{this.props.totalPrice} դրամ</td>
                      </tr>
                    </tbody>
                  </table>
                  <Link to='/pay' className='pay__button__cart' onClick={this.props.handleOpenCart}>{translation.placeOrder}</Link>
                </div>
              ) : (
                <div className="cart__empty">
                  <span role="img" aria-label="Warning">❗</span> {translation.emptyCart}
                </div>
              )}
            </div>
          </div>
        }
      </div>
    )
  }
}

export default Cart