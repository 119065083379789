import React, { Component } from 'react'
import './About.css'


export class Login extends Component {
  render() {
    return (
<div className="about__container">
            <div className="about__container__img">
                <img src="path_to_image.jpg" alt="About Us" />
            </div>
            <div className="about__container__text">
                <h1>About Us</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam.</p>
                <a href="/contact">Contact Us</a>
            </div>
        </div>
    )
  }
}

export default Login