import React, { Component } from 'react'
import './Pay.css'
import { RiDeleteBin6Line } from 'react-icons/ri';

export class Pay extends Component {

    constructor(props) {
        super(props)
        this.state = {
            "name": "",
            "last_name": "",
            "email": "",
            "address": "",
            "phone": "",
            "emailError": "",

            showLoading: false,
            loading: false,

        }
    }


    handlePay = () => {
        // Assuming there's some initial validation or setup here
        this.setState({ loading: true });

        // Simulate a payment process with a timeout or replace this with your actual payment logic
        setTimeout(() => {
            // After the payment process is done, you might want to do something here
            // For example, showing the success message by setting showLoading to true
            // and eventually setting loading to false to hide the loader and show the modal
            this.setState({ showLoading: true }); // Assuming you have a state named showLoading to control the modal visibility
        }, 2000); // Simulate a 2-second payment processing delay
    };

    handleGetCustomerInfo = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === "email") {
            this.validateEmail(e.target.value); // Step 3
        }
    };

    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            this.setState({ emailError: "Invalid email address" }); // Step 2b
        } else {
            this.setState({ emailError: "" }); // Step 2b
        }
    };

    // Language

    getTranslation(language) {
        const translations = {
            en: {
                fillCustomerDetails: "Fill in the customer details",
                name: "Name*",
                lastName: "Last Name*",
                email: "Email",
                address: "Address*",
                phone: "Phone*",
                product: "Product",
                quantity: "Quantity",
                price: "Price",
                total: "Total",
                registerOrder: "Register Order",
                cartEmpty: "The cart is empty",
                orderSentSuccess: "Order sent successfully!",
                allFieldsRequired: "Please fill in all fields.",
                invalidEmail: "Invalid email address.",
                dram: '֏',
            },
            rus: {

                fillCustomerDetails: "Заполните данные клиента",
                name: "Имя*",
                lastName: "Фамилия*",
                email: "Email",
                address: "Адрес*",
                phone: "Телефон*",
                product: "Продукт",
                quantity: "Количество",
                price: "Цена",
                total: "Итого",
                registerOrder: "Зарегистрировать заказ",
                cartEmpty: "Корзина пуста",
                orderSentSuccess: "Заказ успешно отправлен!",
                allFieldsRequired: "Пожалуйста, заполните все поля.",
                invalidEmail: "Неверный адрес электронной почты.",
                dram: "др.",
            },
            հայ: {
                fillCustomerDetails: "Լրացրեք հաճախորդի տվյալները",
                name: "Անուն*",
                lastName: "Ազգանուն*",
                email: "Էլ․ հասցե",
                address: "Հասցե*",
                phone: "Հեռախոս*",
                product: "Ապրանք",
                quantity: "Քանակ",
                price: "Գին",
                total: "Ընդամենը",
                registerOrder: "Գրանցել պատվեր",
                cartEmpty: "Զամբյուղը դատարկ է",
                orderSentSuccess: "Պատվերը ուղարկված է",
                allFieldsRequired: "Խնդրում ենք լրացնել բոլոր դաշտերը։",
                invalidEmail: "Անվավեր էլ. հասցե։",
                dram: "դր.",
            }
        }
        return translations[language];
    }
    z



    render() {
        const cart_items = this.props.cart;
        const { emailError } = this.state;
        const { showLoading } = this.state;

        const language = this.props.language;
        const translation = this.getTranslation(language);

        return (
            <>
                <div className="container main-content-order">

                    <div className="pay__page__content">
                        <div className="customer__form">
                            <h2>{translation.fillCustomerDetails}</h2>
                            <form>
                                <input type="text" name="name" placeholder={translation.name} value={this.state.name} onChange={this.handleGetCustomerInfo} />
                                <input type="text" name="last_name" placeholder={translation.lastName} value={this.state.last_name} onChange={this.handleGetCustomerInfo} />

                                <input type="email" style={{ color: emailError ? 'red' : 'initial' }} name="email" placeholder={translation.email} value={this.state.email} onChange={this.handleGetCustomerInfo} />
                                <input type="text" name="address" placeholder={translation.address} value={this.state.address} onChange={this.handleGetCustomerInfo} />
                                <input type="text" name="phone" placeholder={translation.phone} value={this.state.phone} onChange={this.handleGetCustomerInfo} />
                            </form>
                        </div>
                        <div className="pay__table">
                            {cart_items.length > 0 ? (
                                <div className="item__table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>{translation.product}</th>
                                                <th>{translation.quantity}</th>
                                                <th>{translation.price}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cart_items.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.description}</td>
                                                    <td className='item__table__quantity__number'>
                                                        <button onClick={(e) => { e.stopPropagation(); this.props.decrementQuantity(item); }}>-</button>
                                                        {item.quantity}
                                                        <button onClick={(e) => { e.stopPropagation(); this.props.incrementQuantity(item); }}>+</button>
                                                    </td>
                                                    <td>{parseInt(item.price)} {translation.dram}</td>
                                                    <td onClick={() => this.props.removeFromCart(item)}><RiDeleteBin6Line /></td>
                                                </tr>
                                            ))}
                                            <tr className="total">
                                                <td>{translation.total}</td>
                                                <td></td>
                                                <td>{parseInt(this.props.totalPrice)} {translation.dram}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button className='pay__button' onClick={() => {
                                        const { name, last_name, address, phone } = this.state;
                                        if (!name || !last_name || !address || !phone) {
                                            // Optionally, alert the user that all fields except email are required.
                                            alert(translation.allFieldsRequired);
                                            return; // Do not proceed with sendOrder
                                        } else if (emailError) {
                                            alert(translation.invalidEmail);
                                            return; // Do not proceed with sendOrder
                                        } else {
                                            this.handlePay();
                                        }
                                        this.props.sendOrder({
                                            name: name,
                                            last_name: last_name,
                                            email: this.state.email,
                                            address: address,
                                            phone: phone,
                                        });
                                    }}>{translation.registerOrder}</button>
                                </div>

                            ) : (
                                <div className="cart__empty">
                                    <span role="img" aria-label="Warning">❗</span> {translation.cartEmpty}
                                </div>

                            )}

                            {this.state.loading ? (
                                <div className="send__order__loading">
                                    {showLoading ? (
                                        <div className="modal__order__is__send">
                                            <div className="modal__content">
                                                <span className="close" onClick={() =>
                                                    this.setState({ loading: false })
                                                }>×</span>
                                                <p>{translation.orderSentSuccess}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="loading"></div>
                                    )}
                                </div>
                            ) : (
                                <div></div>
                            )}

                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default Pay