import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header"; // Importing Header component
import Footer from "./components/Footer/Footer"; // Importing Footer component
import Main from "./components/Main/Main"; // Importing Main component
import Cookies from 'js-cookie'; // Importing Cookies library
import About from "./components/About/About"; // Importing Login component
import Pay from "./components/Pay/Pay"; // Importing Pay component
require('dotenv').config();

const DocumentTitle = ({ title }) => {
    useEffect(() => {
        document.title = title; // Set document title
    }, [title]);

    return null;
};

class App extends React.Component {
    constructor(props) {
        super(props)

        const cart = Cookies.get('cart'); // Get cart from cookies
        this.state = {
            searchText: "",
            products: [],
            categories: [],

            // Cart
            cart: cart ? JSON.parse(cart) : [], // Parse cart from cookies
            cartIsOpen: false,
            totalPrice: 0,
            totalItems: 0,

            // Language
            language: 'հայ', // Set default language
        }
    }

    url = `https://${process.env.REACT_APP_HOST_IP}`; // Set API URL

    componentDidMount() {
        // Retrieve other data as before
        fetch(this.url + '/api/products/')
            .then(response => response.json())
            .then(data => this.setState({ products: data })); // Set products state

        fetch(this.url + '/api/categories/')
            .then(response => response.json())
            .then(data => this.setState({ categories: data })); // Set categories state

        this.totalPrice(); // Calculate total price
        this.totalItems(); // Calculate total items
    }

    // End Session

    handleSearchText = (e) => {
        this.setState({
            searchText: e.target.value // Update search text
        });
        if (window.location.pathname !== '/') {
            window.location.href = '/'; // Redirect to home page
        }
    }

    // Cart
    addToCart = (product) => {
        this.setState(prevState => {
            // Check if the product is already in the cart
            const existingProductIndex = prevState.cart.findIndex(item => item.id === product.id);
            let updatedCart = [...prevState.cart];
    
            if (existingProductIndex >= 0) {
                // Product exists, update the quantity if it doesn't exceed the count
                const updatedProduct = { ...updatedCart[existingProductIndex] };
                if (updatedProduct.quantity < product.count) {
                    updatedProduct.quantity = updatedProduct.quantity ? updatedProduct.quantity + 1 : 2;
                    updatedCart[existingProductIndex] = updatedProduct;
                }
            } else {
                // Product doesn't exist, add to the cart with a quantity of 1 if count is sufficient
                if (product.count > 0) {
                    const newProduct = { ...product, quantity: 1 };
                    updatedCart.push(newProduct);
                }
            }
    
            // Serialize and save the updated cart to a cookie
            Cookies.set('cart', JSON.stringify(updatedCart), { expires: 7 }); // Expires in 7 days
            return { cart: updatedCart };
        }, () => {
            this.totalPrice(); // Recalculate total price after updating the cart
            this.totalItems(); // Recalculate total items after updating the cart
        });
    }

    // Increment/Decrement quantity

    incrementQuantity = (product) => {
        this.setState(prevState => {
            const updatedCart = prevState.cart.map(item => {
                if (item.id === product.id && item.quantity < product.count) {
                    return { ...item, quantity: item.quantity + 1 };
                }
                return item;
            });
            return { cart: updatedCart };
        }, () => {
            this.totalPrice();
            this.totalItems();

            Cookies.set('cart', JSON.stringify(this.state.cart), { expires: 7 }); // Expires in 7 days
        });
    };

    decrementQuantity = (product) => {
        this.setState(prevState => {
            const updatedCart = prevState.cart.map(item => {
                if (item.id === product.id) {
                    return { ...item, quantity: item.quantity - 1 };
                }
                return item;
            }).filter(item => item.quantity > 0);
            return { cart: updatedCart };
        }, () => {
            this.totalPrice();
            this.totalItems();
    
            Cookies.set('cart', JSON.stringify(this.state.cart), { expires: 7 }); // Expires in 7 days
        });
    };

    removeFromCart = (product) => {
        this.setState(prevState => {
            const updatedCart = prevState.cart.filter(p => p.id !== product.id);
            // Serialize and save the updated cart to a cookie
            Cookies.set('cart', JSON.stringify(updatedCart), { expires: 7 }); // Expires in 7 days
            return { cart: updatedCart };
        }, () => {
            this.totalPrice(); // Recalculate total price after updating the cart
            this.totalItems(); // Recalculate total items after updating the cart
        });
    }

    totalPrice = () => {
        let total = 0;
        this.state.cart.forEach((item) => {
            total += item.price * item.quantity;
        });
        this.setState({ totalPrice: total }); // Set total price
    }

    totalItems = () => {
        let count = 0;
        this.state.cart.forEach((item) => {
            count += item.quantity;
        });
        // Save count to cookies
        this.setState({ totalItems: count }); // Set total items
    }

    handleOpenCart = () => {
        this.setState({ cartIsOpen: !this.state.cartIsOpen }); // Toggle cart open/close
    }

    onlyOpenCart = () => {
        this.setState({ cartIsOpen: true }); // Open cart
    }

    // Send order to the backend

    sendOrder = (customer) => {
        const order = {
            items: this.state.cart.map(item => ({
                product: item.id,
                quantity: item.quantity
            })),
            total: this.state.totalPrice,
            customer: customer
        };

        fetch(`https://${process.env.REACT_APP_HOST_IP}/api/create_order/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(order),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                // Handle success response
            })
            .catch((error) => {
                console.error('Error:', error);
                // Handle error response
            });
    }

    // Language
    changeLanguage = (language) => {
        this.setState({ language });
    }

    getTitlesTranslations(language) {
        const translations = {
            en: {
                title: "Home page | MARCA CA",
                orderTitle: "Order registration | MARCA CA",
            },
            rus: {
                title: "Главная страница | МАРКА КА",
                orderTitle: "Регистрация заказа | МАРКА КА",
            },
            հայ: {
                title: "Գլխավոր էջ | ՄԱՐԿԱ ԿԱ՛",
                orderTitle: "Պատվերի գրանցում  | ՄԱՐԿԱ ԿԱ՛",
            }
        }

        return translations[language];
    }

    render() {
        return (
            <>
                <Router>

                    <Header
                        products={this.state.products}
                        handleSearchText={this.handleSearchText}
                        cart={this.state.cart}
                        cartIsOpen={this.state.cartIsOpen}
                        handleOpenCart={this.handleOpenCart}
                        removeFromCart={this.removeFromCart}
                        totalPrice={this.state.totalPrice}
                        totalItems={this.state.totalItems}
                        changeLanguage={this.changeLanguage}

                        language={this.state.language}

                        incrementQuantity={this.incrementQuantity}
                        decrementQuantity={this.decrementQuantity}
                    />

                    <Routes>
                        <Route path="/" element={
                            <>
                                <DocumentTitle title={this.getTitlesTranslations(this.state.language).title} />
                                <Main
                                    products={this.state.products}
                                    searchText={this.state.searchText}
                                    categories={this.state.categories}
                                    addToCart={this.addToCart}
                                    onlyOpenCart={this.onlyOpenCart}

                                    language={this.state.language}


                                />
                            </>
                        } />

                        <Route path="/about" element={<About />} />
                        <Route path="/pay" element={
                            <>
                                <DocumentTitle title={this.getTitlesTranslations(this.state.language).orderTitle} />
                                <Pay
                                    totalItems={this.state.totalItems}
                                    cart={this.state.cart}
                                    totalPrice={this.state.totalPrice}
                                    removeFromCart={this.removeFromCart}
                                    sendOrder={this.sendOrder}

                                    language={this.state.language}

                                    incrementQuantity={this.incrementQuantity}
                                    decrementQuantity={this.decrementQuantity}
                                />
                            </>
                        }
                        />
                    </Routes>

                    <Footer
                        language={this.state.language}
                    />
                </Router>
            </>
        );
    }
}

export default App;
