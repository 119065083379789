import React, { Component } from 'react'
import './Filter.css'
require('dotenv').config();

export class Filter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
        }
    }

    handleOpenCloseFilter = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }


    getTranslation(language) {
        const translations = {
            en: {
                price: 'Price',
                inStock: 'In Stock',
                category: 'Category',
                minPrice: 'Min',
                maxPrice: 'Max',
                filter_title: 'Filter'
            },
            rus: {
                price: 'Цена',
                inStock: 'В наличии',
                category: 'Категория',
                minPrice: 'Мин',
                maxPrice: 'Макс',
                filter_title: 'Фильтр'
            },
            հայ: {
                price: 'Գին',
                inStock: 'Առկա է',
                category: 'Կատեգորիա',
                minPrice: 'սկսած',
                maxPrice: 'մինչև',
                filter_title: 'Ֆիլտր'
            }
        }

        return translations[language];
    }

    render() {

        const categories = this.props.categories;
        const language = this.props.language;
        const isOpen = this.state.isOpen;

        return (
            <>
                <h4 className='main__page__filter__title' onClick={this.handleOpenCloseFilter}>{this.getTranslation(this.props.language).filter_title}</h4>
                <div className={`filter ${isOpen ? 'filter__show' : 'filter__hide'}`}>
                    <div className="price__filter">
                        <span>
                            {this.getTranslation(this.props.language).price}
                        </span>
                        <div className="ptice__inputs">
                            <input type="number" placeholder={this.getTranslation(this.props.language).minPrice} className='filter__input' onInput={this.props.minPrice} />
                            <input type="number" placeholder={this.getTranslation(this.props.language).maxPrice} className='filter__input' onInput={this.props.maxPrice} />
                        </div>
                    </div>
                    <div className="in_stock">
                        <label htmlFor="in_stock">{this.getTranslation(this.props.language).inStock}</label>
                        <div className="toggle">
                            <label className="switch">
                                <input type="checkbox" id='in_stock' checked={this.state.inStock} onClick={this.props.handleClick} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div className="category">
                        <span>{this.getTranslation(this.props.language).category}</span>
                        <ul>
                            {categories && categories.map((category) => {
                                return (
                                    <li key={category.id}>
                                        <input type="checkbox" id={'category_' + category.id} onChange={this.props.handleSelectCategory} />
                                        <label htmlFor={'category_' + category.id}>{language === 'հայ' ? category.name : language === 'rus' ? category.rus : language === 'en' ? category.eng : null}</label>
                                    </li>
                                )
                            }
                            )}

                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

export default Filter