import React, { Component } from 'react'
import './Main.css'
import Filter from './Filter/Filter'
import Products from './Products/Products'
require('dotenv').config();

export class Main extends Component {

    constructor(props) {
        super(props)
        this.state = {
            inStock: false,
            minPrice: 0,
            maxPrice: 0,
            products: [],
            categories: [],
        }
    }


    url = `https://${process.env.REACT_APP_HOST_IP}`

    componentDidMount() {
        
        fetch(this.url + '/api/products/')
            .then(response => response.json())
            .then(data => this.setState({ products: data }));

        fetch(this.url + '/api/categories/')
            .then(response => response.json())
            .then(data => this.setState({ categories: data }));
    }



    handleClick = (event) => {
        this.setState({ inStock: !this.state.inStock })
    }

    minPrice = (event) => {
        this.setState({ minPrice: event.target.value })
    }

    maxPrice = (event) => {
        this.setState({ maxPrice: event.target.value })
    }

    handleSelectCategory = (event) => {
        this.setState({ categories: this.state.categories.map(category => {
            if(category.id === parseInt(event.target.id.split('_')[1])) {
                category.selected = !category.selected
            }
            return category;
        })
    }
    )
    }



  render() {
    return (
      <>
      <section className='container main-content'>
                {/* <Categories products={this.products} categories={this.categories}/> */}
                <Filter 
                    handleClick={ this.handleClick } 
                    minPrice={this.minPrice} 
                    maxPrice={this.maxPrice} 
                    categories={this.props.categories} 
                    handleSelectCategory={this.handleSelectCategory}

                    language={this.props.language}
                />
                <Products 
                    products={this.state.products}  
                    inStock={this.state.inStock} 
                    maxPrice={this.state.maxPrice} 
                    minPrice={this.state.minPrice}
                    searchText={this.props.searchText} 
                    categories={this.state.categories}
                    addToCart={this.props.addToCart}
                    onlyOpenCart={this.props.onlyOpenCart}

                    language={this.props.language}
                />
      </section>
      </>
    )
  }
}

export default Main