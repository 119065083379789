import React, { Component } from 'react'
import './Footer.css'

export class Footer extends Component {

  getTranslation = (language) => {
    const translations = {
      en: {
      allRightsReserved: 'All rights reserved',
      company: '"MARCA CA" LLC'
      },
      rus: {
      allRightsReserved: 'Все права защищены',
      company: '"МАРКА КА" ООО'
      },
      հայ: {
      allRightsReserved: 'Բոլոր իրավունքները պաշտպանված են',
      company: '«ՄԱՐԿԱ ԿԱ» ՍՊԸ'
      }
    }

    return translations[language];
  }

  render() {
    const currentYear = new Date().getFullYear();
    return (
      <div>
        <footer className="footer">
            <div className="container-header">
                <p> &copy; {this.getTranslation(this.props.language).company}, {currentYear}. {this.getTranslation(this.props.language).allRightsReserved}</p>
            </div>
        </footer>
      </div>
    )
  }
}

export default Footer