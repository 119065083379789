import React, { Component } from 'react'
import './ProductModal.css'
import defaultImagePath from '../../../../assets/default.png'

export class ProductModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidUpdate(prevProps) {
        // Check if the product image_urls have changed
        if (this.props.product.image_urls !== prevProps.product.image_urls) {
            this.setState({
                mainImageUrl: this.props.product.image_urls && this.props.product.image_urls.length > 0 ? this.props.product.image_urls[0] : defaultImagePath
            });
        }
    }
    setMainImage = (imageUrl) => {
        this.setState({
            mainImageUrl: imageUrl
        });
    }


    // Language

    getTranslation(language) {
        const translations = {
            en: {
            inStock: 'In stock',
            notInStock: 'Not in stock',
            dram: 'dram',
            forOnePiece: 'for one piece',
            addToCart: 'Add to cart',
            pcs: 'pcs',
            },
            rus: {
            inStock: 'В наличии',
            notInStock: 'Нет в наличии',
            dram: 'драм',
            forOnePiece: 'за штуку',
            addToCart: 'Добавить в корзину',
            pcs: 'шт',
            },
            հայ: {
            inStock: 'Առկա է',
            notInStock: 'Առկա չէ',
            dram: 'դրամ',
            forOnePiece: 'մեկ հատի համար',
            addToCart: 'Ավելացնել զամբյուղ',
            pcs: 'հատ',
            }
        }

        return translations[language];
    }

    render() {
        const modalIsActive = this.props.modalIsActive;
        const product = this.props.product;
        const { mainImageUrl } = this.state;

        return (
            <div className="product__modal" style={{ display: modalIsActive ? 'block' : 'none' }} onClick={this.props.closeModal}>
                <div className="product__modal__main" onClick={(e) => e.stopPropagation()}>
                <span className="close__modal" onClick={this.props.closeModal}>&times;</span>
                    <div className="product__modal__window">
                        <div className="product__modal__header">
                            <h2 className='product__modal__header__title'>{product.description}</h2>
                        </div>
                        <div className="product__modal__body">

                            <div className="product__modal__images">
                                <img className='product__modal__main__image' src={mainImageUrl} alt="img" />

                                <div className="product__modal__mini__images">
                                    {product.image_urls && product.image_urls.map((img, index) => (
                                        <img className='product__modal__mini__image' key={index} src={img} alt="Product" onClick={() => this.setMainImage(img)} />
                                    ))}
                                </div>
                            </div>


                            <div className="product__modal__body__text__info">
                                <div className="product__modal__main__section">
                                    <div className="product__modal__vin__and__second__description">
                                        <span className='product__modal__vin'>VIN {product.vin}</span>
                                        <p className='product__modal__second__description'>
                                            {product.second_description}
                                        </p>
                                    </div>
                                    
                                    <div className="product__modal__price__and__stock">
                                        <div className="product__modal__price__div">
                                            <span className="product__modal__price">{parseInt(product.price)} {this.getTranslation(this.props.language).dram}</span>
                                            <span className='product__modal__one__text'>{this.getTranslation(this.props.language).forOnePiece}</span>
                                        </div>
                                        <div className="product__modal__instock__count">
                                        <span className={`product__modal__stock ${product.inStock ? 'in__stock' : 'not__in__stock'}`}>
                                            {product.inStock ?  this.getTranslation(this.props.language).inStock : this.getTranslation(this.props.language).notInStock}
                                        </span>
                                        <span>{product.inStock ? `${product.count} ${this.getTranslation(this.props.language).pcs}` : ''}</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="product__modal__footer">
                                    {product.inStock ? (

                                        <button className="btn" onClick={() => { this.props.addToCart(product); this.props.onlyOpenCart(); this.props.closeModal()}}>{this.getTranslation(this.props.language).addToCart}</button>
                                    ) : (
                                        <button className="btn_disabled" disabled>{this.getTranslation(this.props.language).addToCart}</button>
                                    )
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default ProductModal