import React, { Component } from 'react';
import { IoMdMenu } from "react-icons/io";
import "./MobileMenu.css";
import logo from "../../../assets/logo.png";
import { Link } from "react-router-dom";



export class MobileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            languagePanelIsOpen: false,
        };
        this.clickOutside = this.clickOutside.bind(this);
    }

    openCloseLanguagePanel = () => {
        this.setState({
            languagePanelIsOpen: !this.state.languagePanelIsOpen,
        });
    };

    toggleMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    clickOutside = (e) => {
        if (e.target.classList.contains('mobile__menu__empty__space')) {
            this.setState({
                isOpen: false,
            });
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.clickOutside);
    }

    componentWillUnmount() {
        // Clean up to avoid memory leaks
        document.removeEventListener('click', this.clickOutside);
    }

    getTranslation(language) {
        const translations = {
            en: {
                call_us: 'Call us',
                about: 'About',
            },
            rus: {
                call_us: 'Позвоните нам',
                about: 'О нас',
            },
            հայ: {
                call_us: 'Զանգահարե՛ք մեզ',
                about: 'Մեր մասին',
            }

        }

        return translations[language];
    }


    render() {
        const menuClass = `mobile__menu ${this.state.isOpen ? 'open' : 'close'}`;
        return (
            <>
                <div className="mobile__menu">
                    <IoMdMenu className="mobile-menu-icon" onClick={this.toggleMenu} />
                    {this.state.isOpen ? <div className="mobile__menu__empty__space"></div> : null}
                    <div className={`mobile__menu__source ${menuClass}`}>
                        <ul className='mobile__menu__source__ul'>
                            <li>
                                <Link to='/'>
                                    <img src={logo} alt="Logo" />
                                </Link>
                            </li>
                            <li>
                                <Link to='/about' className='mobile__about'>
                                    {this.getTranslation(this.props.language).about}
                                </Link>
                            </li>
                            <ul className='mobile__language__panel'>
                                <li onClick={() => this.props.changeLanguage('հայ')}>ՀԱՅ</li>
                                <li onClick={() => this.props.changeLanguage('en')}>EN</li>
                                <li onClick={() => this.props.changeLanguage('rus')}>RUS</li>
                            </ul>
                            <li className='mobile__phone__numer'>
                                <a href='tel:099999999'> +374 99-99-99-99</a>
                                <a href='tel:099999999'>
                                    {this.getTranslation(this.props.language).call_us}
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

export default MobileMenu