import React, { Component } from "react";
import "./Header.css";
// import logo from "../../assets/logo512.png";
import logo from "../../assets/logo.png";
import Search from "./Search/Search";
import Cart from "./Cart/Cart";
import MobileMenu from "./MobileMenu/MobileMenu";
import { Link } from "react-router-dom";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languagePanelIsOpen: false,
        };
    }
    products = this.props

    openCloseLanguagePanel = () => {
        this.setState({
            languagePanelIsOpen: !this.state.languagePanelIsOpen,
        });
    };


    getTranslation(language) {
        const translations = {
            en: {
                call_us: 'Call us',
            },
            rus: {
                call_us: 'Позвоните нам',
            },
            հայ: {
                call_us: 'Զանգահարե՛ք մեզ',
            }

        }

        return translations[language];
    }

    render() {

        const { languagePanelIsOpen } = this.state;
        return (
            <section className="header">
                <header>
                    <nav className="">
                        <ul className="container-header">
                            <li>
                                <Link to="/">
                                    <img src={logo} alt="Logo" className="header__logo" />
                                </Link>
                                <MobileMenu
                                    language={this.props.language}
                                    changeLanguage={this.props.changeLanguage}
                                />
                            </li>
                            <li className="header__search">
                                <Search
                                    products={this.products}
                                    handleSearchText={this.props.handleSearchText}

                                    language={this.props.language}
                                />
                            </li>
                            <div className="header__phone__number">
                                <li>
                                    <p> +374 99-99-99-99</p>
                                    <span>
                                        {this.getTranslation(this.props.language).call_us}
                                    </span>
                                </li>
                            </div>
                            <div className="header__buttons">
                                <li className="header__language__change" onClick={this.openCloseLanguagePanel}>{this.props.language.toUpperCase()}
                                    <div className={`header__language__change__dropdown ${languagePanelIsOpen ? '' : 'hidden'}`}>
                                        <ul>
                                            <li onClick={() => this.props.changeLanguage('հայ')}>ՀԱՅ</li>
                                            <li onClick={() => this.props.changeLanguage('en')}>EN</li>
                                            <li onClick={() => this.props.changeLanguage('rus')}>RUS</li>
                                        </ul>
                                    </div>
                                </li>
                                <li><Cart
                                    cart={this.props.cart}
                                    removeFromCart={this.props.removeFromCart}
                                    handleOpenCart={this.props.handleOpenCart}
                                    cartIsOpen={this.props.cartIsOpen}
                                    totalPrice={this.props.totalPrice}
                                    totalItems={this.props.totalItems}

                                    language={this.props.language}

                                    incrementQuantity={this.props.incrementQuantity}
                                    decrementQuantity={this.props.decrementQuantity}
                                />
                                </li>
                                <li>
                                </li>
                            </div>
                        </ul>
                    </nav>
                </header>
            </section>

        );
    }
}

export default Header;