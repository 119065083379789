import React, { Component } from 'react';
import './Products.css';
import ProductModal from './ProductModal/ProductModal';
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import defaultImage from '../../../assets/default.png';

export class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsActive: false,
      selectedProduct: {},
      displayedProductsCount: 16, // Initial number of products to display
    };
  }

  handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      // Near bottom of the page, load more products
      this.setState(prevState => ({
        displayedProductsCount: prevState.displayedProductsCount + 16,
      }));
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleModalIsActive = (product) => {
    this.setState({
      modalIsActive: true,
      selectedProduct: product,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsActive: false,
      selectedProduct: {},
    });
  };

  // Language

  getTranslation(language) {
    const translations = {
      en: {
        inStock: 'In stock',
        notInStock: 'Not in stock',
        dram: '֏',
        noProductsFound: 'No products found.',
      },
      rus: {
        inStock: 'В наличии',
        notInStock: 'Нет в наличии',
        dram: 'др.',
        noProductsFound: 'Товары не найдены.',
      },
      հայ: {
        inStock: 'Առկա է',
        notInStock: 'Առկա չէ',
        dram: 'դր.',
        noProductsFound: 'Ապրանքներ չեն գտնվել։',
      }
    }

    return translations[language];
  }

  render() {
    const { inStock, products, minPrice, maxPrice, categories, searchText } = this.props;
    const { displayedProductsCount } = this.state;

    const filteredProducts = products.filter(product => {
      const price = parseFloat(product.price);
      return (!inStock || product.inStock) &&
        (minPrice === "" || minPrice === 0 || price >= minPrice) &&
        (maxPrice === "" || maxPrice === 0 || price <= maxPrice) &&
        (product.description.toLowerCase().includes(searchText.toLowerCase()) || product.vin.toLowerCase().includes(searchText.toLowerCase())) &&
        (categories.filter(category => category.selected).length === 0 || categories.filter(category => category.selected && category.id === product.category).length > 0);
    }).slice(0, displayedProductsCount); // Only take the number of products to be displayed

    return (
      <>
        {filteredProducts.length === 0 ? (
          <div className='products__no__found'>{this.getTranslation(this.props.language).noProductsFound}</div>
        ) : (
          <>
          <ul className='products'>
            {filteredProducts.map((product) => (
              <li key={product.id} className='product__main'>
                <div className="product" onClick={() => this.handleModalIsActive(product)}>
                  <div className="product__image__and__description">
                    <img src={product.image_urls[0] || defaultImage} alt={product.name} />
                    <div className="product-description">{product.description}</div>
                  </div>

                  <div className="product__price__and__buy">
                    <div className="product-price">{parseInt(product.price)} {this.getTranslation(this.props.language).dram}</div>
                    <div className="product-add-to-cart">
                      {product.inStock ? <span style={{ color: "green" }}>{this.getTranslation(this.props.language).inStock}</span> : <span style={{ color: "red" }}>{this.getTranslation(this.props.language).notInStock}</span>}
                      <span className='icon-add-to-cart'><MdOutlineShoppingCartCheckout /></span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          </>
        )}

        <ProductModal
          modalIsActive={this.state.modalIsActive}
          closeModal={this.closeModal}
          product={this.state.selectedProduct}
          addToCart={this.props.addToCart}
          onlyOpenCart={this.props.onlyOpenCart}

          language={this.props.language}
        />
      </>
    );
  }
}

export default Products;