import React, { Component } from 'react';
import "./Search.css";
import { IoIosSearch } from "react-icons/io";

export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: '',
    };
  }

  updatePlaceholder = () => {
    const screenWidth = window.innerWidth;
    const language = this.props.language;
    const translations = this.getTranslation(language);
    let placeholder = translations.placeholder;

    if (screenWidth <= 431) {
      // Define a shorter placeholder for small screens
      placeholder = translations.shortPlaceholder || 'Search...'; // Fallback to 'Search...' if no shortPlaceholder is defined
    }

    this.setState({ placeholder });
  }


  componentDidUpdate(prevProps){
    if (prevProps.language !== this.props.language) {
      this.updatePlaceholder();
    }
  }

  componentDidMount() {
    this.updatePlaceholder();
    window.addEventListener('resize', this.updatePlaceholder);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePlaceholder);
  }

  getTranslation(language) {
    const translations = {
      en: {
        placeholder: 'Enter the product name or VIN code',
        shortPlaceholder: 'Product/VIN',
      },
      rus: {
        placeholder: 'Введите название товара или VIN код',
        shortPlaceholder: 'Товар/VIN',
      },
      հայ: {
        placeholder: 'Մուտքագրեք ապրանքի անունը կամ VIN կոդը',
        shortPlaceholder: 'Ապրանք/VIN',
      }
    }

    return translations[language];
  }

  render() {
    return (
      <>
        <div className="search">
          <IoIosSearch className="search__icon" />
          <input type="text" name="search" id="search" placeholder={this.state.placeholder} onChange={this.props.handleSearchText} />
        </div>
      </>
    );
  }
}

export default Search;